@import "../../styles/util";

.thank_you {
  .thank_you_text {
    padding: 31px 0;

    p {
      font-size: size(26);
      line-height: size(45);
      margin: 0;
      font-weight:500;

      @include max(991.98){
        font-size: size(22);
        line-height: size(35);
      }
      @include max(767.98) {
        font-size: size(19);
        line-height: size(32);
      }
      span {
        color: $primary-color;
      }
    }
  }
}
