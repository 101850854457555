$primary-font :  'Exo', sans-serif;
$primary-color :  #00AD00;
$secondary-color :  #04553C;
$Third-color: #1D1D1B;
$fourth-color: #82B92B;
$fifth-color: #D5D900;
$light-color : #FFFDEF;
$black-color: #000000;
$white-color: #ffffff;

$dark-green: #1d7f35;

$line-color: #EBEBEB;




@mixin px-rem($pxValue, $property: "font-size", $standard-size: 18) {
    #{$property}: $pxValue/$standard-size + rem;
  }
  
  $base-size: 18;
  
  @function size($target, $context: $base-size) {
    @return ($target / $context) * 1rem;
  }
  
  @mixin min-max($resMin, $resMax) {
    @media (min-width: $resMin+px) and (max-width: $resMax+px) {
      @content;
    }
  }
  
  @mixin max($res) {
    @media (max-width: $res+px) {
      @content;
    }
  }
  
  @mixin min($res) {
    @media (min-width: $res+px) {
      @content;
    }
  }
  
  @mixin aspectRatio($w, $h) {
    padding-bottom: calc(($h / $w) * 100%);
    position: relative;
    & > img {
      position: absolute;
      left: 0;top: 0;width: 100%;
      height: 100%;
    }
  }
  @keyframes iconpop {
    0% {
      transform: scale(1,1);
    }
    50% {
      transform: scale(1.1,1.1);
    }
    100% {
      transform: scale(1,1);
    }
  }