@import "../../../../styles/util";

.gm_contact_home {
    padding: 55px 0;
    position: relative;
    background-color: #FFFFFF;

    @include min(992) {
        padding-top: size(140);
        padding-bottom: size(107);
    }

    :global(.h6) {
        padding-top: size(36);
        margin-bottom: size(10);
    }

    p{
        font-size: size(16);
        line-height: 1.6;
        color: #000;
        text-decoration: none;
        font-weight: 400;
        max-width: size(390);
        width: 100%;
        margin-bottom: size(40);
        a{
            color: #000;
            transition: .3s color ease-in-out;
            text-decoration: none;
            font-weight: 600;
            &:hover{
                color: #1d7f35;
            }
        }
    }


    .head_contact {
        z-index: 1;
        position: relative;
        margin-bottom: 30px;

        @include min(992) {
            margin-bottom: size(26);

        }

        line-height: 1;

        :global {
            .char::before {
                color: #000;
            }
            .head_lets {
                .char::before {
                    -moz-text-fill-color: rgba(255, 255, 255, 0);
                    -webkit-text-fill-color: rgba(255, 255, 255, 0);
                    -moz-text-stroke-color: black;
                    -webkit-text-stroke-color: black;
                    -moz-text-stroke-width: 1px;
                    -webkit-text-stroke-width: 1px;
                }
              
              }
            [data-word="Let's"] {
                .char::before {
                    -moz-text-fill-color: rgba(255, 255, 255, 0);
                    -webkit-text-fill-color: rgba(255, 255, 255, 0);
                    -moz-text-stroke-color: black;
                    -webkit-text-stroke-color: black;
                    -moz-text-stroke-width: 1px;
                    -webkit-text-stroke-width: 1px;
                }

            }
        }


    }

    .contact_banner {
        padding-top: calc((590/604)*100%);
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        &_wrap {
            max-width: size(604);
            margin-top: -8rem;
            position: relative;
        }
    }
}

.contact_leaf1,
.contact_leaf2 {
    position: absolute;

}

.contact_leaf1 {
    width: 160px;
    left: -4%;
    top: 19%;
    z-index: 1;
}

.contact_leaf2 {
    bottom: -6%;
    right: 16%;
    width: 135px;
}



.success{
    width: 15px;
    height: 15px;
    display: inline-block !important;
    margin-left: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.274' height='7.307' viewBox='0 0 10.274 7.307'%3E%3Cpath id='Path_2314' data-name='Path 2314' d='M-1927.237,5371.8l-3.675-3.482.688-.726,2.968,2.812,5.911-5.911.707.707Z' transform='translate(1930.912 -5364.496)' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-size: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.failed{
    width: 15px;
    height: 15px;
    display: inline-block !important;
    margin-left: 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8.802' height='8.803' viewBox='0 0 8.802 8.803'%3E%3Cpath id='Union_26' data-name='Union 26' d='M4.4,5.108.707,8.8,0,8.1,3.694,4.4,0,.707.707,0,4.4,3.694,8.1,0,8.8.707,5.108,4.4,8.8,8.1,8.1,8.8Z' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-size: 15px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.contact_icon_wrap{
    a {
        display: flex;
        align-items: center;
        flex-basis: 130px;
        flex-shrink: 0;
    }
}