@import "../../styles/util";
// .productPage {
//   width: 100%;
//   position: relative;
//   padding: 130px 0px;

//   img {
//     display: block;
//   }

//   @include max(576) {
//     padding: 80px 0px;
//   }
// }
// .popupMask {
//   width: 100vw;
//   height: 100vh;
//   position: fixed;
//   left: 0;
//   top: 0;
//   background-color: rgba(0, 0, 0, 0.555);
//   z-index: 99999;
//   opacity: 0;
//   pointer-events: none;
//   transition: 0.3s all ease-in-out;
//   &.open {
//     opacity: 1;
//     pointer-events: all;
//   }
// }

// .cardWrapper {
//   width: 100vw;
//   position: fixed;
//   height: 100vh;
//   z-index: 99999999;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   opacity: 0;
//   pointer-events: none;
//   transition: 0.3s all ease-in-out;
//   padding: 15px;
//   &.open {
//     opacity: 1;
//     pointer-events: all;
//   }

//   .cardInner {
//     width: 100%;
//     position: relative;
//     background-color: #fff;
//     padding: 5px;
//     border-radius: 25px;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     max-width: 747px;
//     @media (max-width: 767px) {
//       max-width: 450px;
//     }
//   }

//   .cardImage {
//     width: 100%;
//     position: relative;
//     border-radius: 20px 20px 0px 0px;
//     overflow: hidden;

//     .cardImageContainer {
//       width: 100%;
//       position: relative;
//       padding-bottom: calc((204 / 737) * 100%);

//       img {
//         position: absolute;
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         object-fit: contain;
//         object-position: center;
//       }
//     }
//     &.lastCHild {
//       width: 92px;
//       margin: auto;
//       margin-top: 67px;
//       .cardImageContainer {
//         padding-bottom: 100%;
//         img {
//           object-fit: contain;
//         }
//       }
//       + .cardContent {
//         padding-top: 34px;
//         h3 {
//           font-size: 26px !important;
//           font-weight: 500 !important;
//         }
//       }
//     }
//   }

//   .cardContent {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     position: relative;
//     text-align: center;
//     padding: 60px 30px;
//     @media (max-width: 767px) {
//       padding: 20px 0px;
//     }

//     .cardHeading {
//       width: 100%;
//       position: relative;
//       @media (max-width: 767px) {
//         padding: 0px 50px;
//       }

//       .cardTop {
//         width: 100%;
//         position: relative;
//         margin-bottom: 30px;

//         h3 {
//           width: 100%;
//           text-align: center;
//           font-size: 32px;
//           line-height: 1.45;
//           color: #000;
//           margin-bottom: 10px;
//           font-weight: 600;
//           max-width: 610px;
//           margin-left: auto;
//           margin-right: auto;
//           @media (max-width: 767px) {
//             font-size: 18px;
//           }
//           span {
//             color: #39aa40;
//           }
//         }
//         p {
//           width: 100%;
//           text-align: center;
//           font-size: 20px;
//           line-height: 1.45;
//           color: #000;
//           margin-bottom: 5px;
//           @media (max-width: 767px) {
//             font-size: 14px;
//           }
//           span {
//             color: #39aa40;
//           }
//         }
//       }

//       .cardBottom {
//         width: 100%;
//         position: relative;
//         margin-bottom: 25px;
//         h4 {
//           width: 100%;
//           text-align: center;
//           font-size: 24px;
//           line-height: 1.5;
//           color: #000;
//           margin-bottom: 5px;
//           font-weight: 600;
//           @media (max-width: 767px) {
//             font-size: 16px;
//           }
//           span {
//             color: #39aa40;
//           }
//           + p {
//             max-width: 500px;
//             width: 100%;
//             display: block;
//             margin-left: auto;
//             margin-right: auto;
//             position: relative;
//             margin-top: 18px;
//             text-align: center;
//           }
//         }
//       }
//     }

//     .cardButton {
//       width: 100%;
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       button {
//         width: auto;
//         position: relative;
//         border: 2px solid #00ad00;
//         padding: 12px 35px;
//         border-radius: 30px;
//         color: #000;
//         transition: 0.3s all ease-in-out;
//         margin: 0px 7px;
//         background-color: transparent;
//         font-size: 18px;
//         line-height: 1;
//         &:hover {
//           background-color: #00ad00;
//           color: #fff;
//         }
//         @media (max-width: 767px) {
//           font-size: 14px;
//           padding: 8px 20px;
//           border: 1px solid #00ad00;
//         }
//       }
//       .form_input_wrap {
//         position: relative;
//         max-width: 400px;
//         width: 100%;
//         @media (max-width: 767px) {
//           max-width: 300px;
//         }
//         input {
//           height: 56px;
//           border: 1px solid #000;
//           border-radius: 40px;
//           text-indent: 20px;
//           color: #000;
//         }
//         .submit_btn {
//           width: 46px;
//           height: 46px;
//           background: #00ad00;
//           position: absolute;
//           top: 50%;
//           right: 0;
//           transform: translateY(-50%);
//           border-radius: 50%;
//           padding: 0;
//           transition: 0.3s ease transform;
//           transform-origin: center;
//           &::before {
//             content: "";
//             width: 100%;
//             height: 100%;
//             background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37.414' height='22.469' viewBox='0 0 37.414 22.469'%3E%3Cg id='Group_667' data-name='Group 667' transform='translate(-345.629 -7213.914)'%3E%3Cpath id='Path_887' data-name='Path 887' d='M35.414 11.233H0a1 1 0 0 1-.924-.617 1 1 0 0 1 .217-1.09L9.526-.707a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414L2.414 9.233h33a1 1 0 1 1 0 2Z' transform='translate(382.043 7235.383) rotate(180)' fill='%23fff'/%3E%3Cpath id='Path_888' data-name='Path 888' d='M6.247 7.246a1 1 0 0 1-.707-.293L-.707.707a1 1 0 0 1 0-1.414 1 1 0 0 1 1.414 0L6.954 5.539a1 1 0 0 1-.707 1.707Z' transform='translate(378.057 7221.16) rotate(180)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
//             background-repeat: no-repeat;
//             background-size: 25px;
//             background-position: 50% 50%;
//             position: absolute;
//             top: 0;
//             left: 0;
//           }
//           &:hover {
//             transform: translateY(-50%) scale(0.9);
//           }
//         }
//       }
//     }
//   }
// }

// .cardImage {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .cardContent h3 {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .cardContent p {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .cardContent h4 {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .cardButton {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .extraImageWrapper {
//   opacity: 0;
//   transition: 0.6s all ease;
//   transform: translateY(-30px);
// }
// .startAnimation {
//   .cardImage {
//     opacity: 1;
//     transform: translateY(0px);
//   }
//   .cardContent h3 {
//     opacity: 1;
//     transform: translateY(0px);
//     transition-delay: 0.08s;
//   }
//   .cardContent p {
//     opacity: 1;
//     transform: translateY(0px);
//     transition-delay: 0.16s;
//   }
//   .cardContent h4 {
//     opacity: 1;
//     transform: translateY(0px);
//     transition-delay: 0.24s;
//   }
//   .cardButton {
//     opacity: 1;
//     transform: translateY(0px);
//     transition-delay: 0.28s;
//   }
//   .extraImageWrapper {
//     opacity: 1;
//     transform: translateY(0px);
//     transition-delay: 0.32s;
//   }
// }

// .extraImageWrapper {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   max-width: 581px;
//   margin-left: auto;
//   margin-right: auto;
//   .extraImageInner {
//     width: auto;
//     display: flex;
//     flex-wrap: wrap;
//     position: relative;
//     margin-bottom: 15px;
//     &:empty {
//       display: none;
//     }
//     .extraImage {
//       width: 50%;
//       display: flex;
//       flex-direction: column;
//       position: relative;
//       padding-left: 10px;
//       padding-right: 10px;

//       .extraImageInner {
//         width: 100%;
//         position: relative;
//         border-radius: 20px;
//         overflow: hidden;
//         border: 2px solid transparent;
//         padding: 5px;

//         .extraImageContainer {
//           width: 100%;
//           position: relative;
//           padding-bottom: calc(169 / 268 * 100%);

//           img {
//             position: absolute;
//             left: 0;
//             top: 0;
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//             object-position: center;
//             overflow: hidden;
//             border-radius: 14px;
//           }
//         }
//       }
//       input {
//         position: absolute;
//         opacity: 0;
//         width: 100%;
//         height: 100%;
//         left: 0;
//         top: 0;
//         z-index: 8;
//         cursor: pointer;
//         &.checked {
//           + .extraImageInner {
//             border: 2px solid #39aa40;
//           }
//         }
//       }
//     }
//   }
// }
.modal {
  .form_input_wrap {
    position: relative;
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 767px) {
      max-width: 300px;
    }

    input {
      height: 56px;
      border: 1px solid #000;
      border-radius: 40px;
      text-indent: 0;
      color: #000;

      @include max(767.98) {
        font-size: 12px;
        text-indent: 10px;
      }
    }

    .submit_btn {
      width: 46px;
      height: 46px;
      background: #00ad00;
      position: absolute;
      border: none;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      border-radius: 50%;
      padding: 0;
      transition: 0.3s ease transform;
      transform-origin: center;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='37.414' height='22.469' viewBox='0 0 37.414 22.469'%3E%3Cg id='Group_667' data-name='Group 667' transform='translate(-345.629 -7213.914)'%3E%3Cpath id='Path_887' data-name='Path 887' d='M35.414 11.233H0a1 1 0 0 1-.924-.617 1 1 0 0 1 .217-1.09L9.526-.707a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414L2.414 9.233h33a1 1 0 1 1 0 2Z' transform='translate(382.043 7235.383) rotate(180)' fill='%23fff'/%3E%3Cpath id='Path_888' data-name='Path 888' d='M6.247 7.246a1 1 0 0 1-.707-.293L-.707.707a1 1 0 0 1 0-1.414 1 1 0 0 1 1.414 0L6.954 5.539a1 1 0 0 1-.707 1.707Z' transform='translate(378.057 7221.16) rotate(180)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 25px;
        background-position: 50% 50%;
        position: absolute;
        top: 0;
        left: 0;
      }

      &:hover {
        transform: translateY(-50%) scale(0.9);
      }
    }
  }
  .text_danger {
    color: rgb(219, 53, 53);
  }

  &:global(.show) {
    display: block;
  }
  .cover {
    padding-top: 70%;
    position: relative;
    background-color: #eee;
    width: 100%;
    border-radius: 1.3889rem 1.3889rem 0 0;
    overflow: hidden;

    @include min(992) {
      padding-top: 30%;
      // padding-top: 14%;
      // width: 82px;
      // margin: 0 auto;
      // margin-top: 55px;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.4;
    margin-bottom: 18px;

    @include min(992) {
      font-size: size(32);
      line-height: size(41);
      margin-bottom: size(30);
    }

    span {
      color: $primary-color;
    }
  }

  p {
    font-size: 16px;
    line-height: 1.3;

    @include min(992) {
      font-size: 1rem;
      line-height: size(26);
    }
  }
  .category {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.45;
    margin-bottom: 17px;

    span {
      color: $primary-color;
    }

    @include min(992) {
      font-size: size(24);
    }
  }

  :global {
    .btn {
      min-width: 100px;
    }

    .modal-dialog {
      @include min(576) {
        max-width: 747px;
      }
    }

    .modal-body {
      padding: 45px 30px 0;
    }

    .modal-header {
      padding: 3px;
      border-bottom: none;
    }

    .modal-content {
      border: none;
      border-radius: 1.3889rem;
      text-align: center;
    }

    .modal-footer {
      border-top: none;
      padding-bottom: 45px;
    }
  }
}

.close_btn {
  width: size(45);
  height: size(45);
  border: 2px solid rgba($black-color, 0.5);
  border-radius: 50%;
}

.modal_header {
  :global {
    .btn-close {
      width: 1.6666666667rem;
      height: 1.6666666667rem;
      border: 2px solid #000000;
      opacity: 0.3;
      border-radius: 50%;
      margin-right: 1rem;
      margin-top: 0;
      transition: opacity 0.3s ease-in-out;
      margin-left: 0;
      position: absolute;
      right: 0;
      top: 20px;
      z-index:9;
      @include max(767.98) {
        width: size(20);
        height: size(20);
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}

.image_btn {
  border-radius: 14px !important;
  padding: size(5);
  border-color: white;
  img {
    pointer-events: none;
    border-radius: 14px;
  }

  &:hover,
  &:active,
  &:focus-visible {
    background-color: white;
    border-color: $primary-color;
  }
  &:focus-visible {
    outline-color: $primary-color;
  }
}
.icon {
  width: 82px;
  margin: 55px auto -15px;
  img {
    width: 100%;
  }
}
