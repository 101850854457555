@import "../../styles/util";

.cursor {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    border: 1px solid #e1e1e1;
    background-color: transparent;
    border-radius: 50%;
    pointer-events: none;
    z-index: 1050;
    will-change: transform;
    transition-property: transform;
    // transition: 0.5s background-color,width,height cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: height 0.5s ease-out, background-color 0.5s ease, width 0.5s ease;

    &::before {
        content: '';
        font-weight: 600;
        font-size: 20px;
        text-align: center;

        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // opacity: 0;
        transition: 0.5s all ease;
        transition-delay: 0.4s;

    }

    &_video, &_drag, &_nav {
        background-color: #00AD00;
        border-color: #00AD00;
        width: 110px;
        height: 110px;

        &::before {
            content: 'PLAY';
            // opacity: 1;


        }
    }
    &_drag {
        &::before {
            content: 'DRAG';
        }
    }
    &_nav {
        width: 90px;
        height: 90px;
        &::before {
            content: 'VIEW';
        }
    }
    &_darken {
        // background-color: #00AD00;
        // border-color: #00AD00;
        width: 200px;
        height: 200px;
        border: none;
        mix-blend-mode: darken;
        background-image: url(../../assets/images/home/shape_globe.svg);
        background-size: cover;
    }
}

